<template>
    <div class="bg-primary window-height window-width row justify-center items-center">
        <div class="column">
            <div class="row">
                <!-- FORMULARIO PARA INICIAR SESION -->
                <q-card square bordered class="q-pa-md shadow-1">
                    <q-card-section class="text-center">
                        <img src="../assets/logo_control.png" width="200" alt="" />

                        <!-- <div class="text-grey-9 text-h5 text-weight-bold">Sign in</div> -->
                        <!-- <div class="text-grey-8">Sign in below to access your account</div> -->
                    </q-card-section>
                    <q-card-section>
                        <q-form class="q-gutter-md">
                            <q-input square filled v-model="nombreU" type="usuario" label="Usuario" />
                            <q-input square filled v-model="password" type="password" label="Contraseña"
                                @keyup.enter="validarUsuario()" />
                        </q-form>
                    </q-card-section>
                    <q-card-actions class="q-px-md">
                        <q-btn unelevated color="primary" size="lg" class="full-width" @click="validarUsuario()"
                            label="INICIAR SESIÓN" />
                    </q-card-actions>
                </q-card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { QSpinnerGears } from 'quasar';
export default {
    name: 'Login',
    data() {
        return {
            password: '',
            nombreU: "",
        }
    },
    computed: {
    },
    methods: {
        async validarUsuario() {
            //VALIDAMOS QUE EL USUARIO SEA VALIDO
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese un nombre de usuario.', timeout: 2000 })
                return
            }
            if (this.password == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese su contraseña.', timeout: 2000 })
                return
            }
            let objeto = {
                usuario: this.nombreU,
                pass: this.password,
                tipo: "Web"
            }

            this.$q.loading.show({ message: '<b>Validando usuario...</b>' })
            try {
                let response = await axios.post(`Usuarios/LoginWeb`, objeto);
                this.$q.loading.hide();
                // console.log(response)
                let data = response.data;

                let objetoToken = {
                    usuario: this.nombreU,
                    pass: this.password,
                    tipo: "Web",
                    id_usuario: data.id_usuario
                }
                this.$store.state.usuario = { objetoToken };
                // console.log(data.token);
                this.$store.dispatch("guardarToken", data.token);
                this.$router.push({ name: "Home" });

                this.nombreU = "";
                this.password = "";
            } catch (err) {
                console.log(err);
                this.$q.notify({ type: 'negative', message: 'Datos incorrectos, intente nuevamente.' });
                this.$q.loading.hide();
            }
            this.$q.loading.hide();

        }
    }
}
</script>

<style>
.q-card {
    width: 500px;
}
</style>
