<template>
    <q-layout style="background: #f1f2f4" view="hHh lpR fFf">
        <q-dialog full-width v-model="dialogNuevoDeudor" persistent>
            <Deudor></Deudor>
        </q-dialog>

        <div class="q-pa-xl q-mx-smq-gutter-sm">
            <q-card class="shadow-0" style="width: 100%;">
                <q-card-section full-width class="text-white">
                    <div class="row">
                        <q-toolbar class="q-mb-md">
                            <q-btn color="primary" icon="mdi-plus" label="Nuevo deudor" @click="nuevoDeudor()" />
                            <q-space />

                            <q-select dense filled v-model="estadoDedudor" style="width:300px" fill-input
                                :options="['Pendiente', 'Asignado']" label="Consultar por Estatus">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No results
                                        </q-item-section>
                                    </q-item>
                                </template>

                                <template v-slot:after>
                                    <q-btn round color="primary" icon="mdi-magnify" @click="getListaDeudores()">
                                        <q-tooltip>
                                            Actualizar Lista
                                        </q-tooltip>
                                    </q-btn>
                                </template>
                            </q-select>


                        </q-toolbar>
                        <div class="col-12 col-md-12">
                            <q-table dense :data="data" :columns="columns" row-key="name" title="Lista de Deudores"
                                :filter="filter" :loading="loading" :pagination="{ rowsPerPage: 10 }">
                                <template v-slot:top-right>
                                    <q-input filled dense debounce="300" v-model="filter" placeholder="Buscar">
                                        <template v-slot:append>
                                            <q-icon name="search" />
                                        </template>
                                    </q-input>
                                </template>
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td key="nombre" :props="props">
                                            {{ props.row.nombre }}
                                        </q-td>
                                        <q-td key="rfc" :props="props">
                                            {{ props.row.rfc }}
                                        </q-td>
                                        <q-td key="placa" :props="props">
                                            {{ props.row.placa }}
                                        </q-td>
                                        <q-td key="codigo_postal" :props="props">
                                            {{ props.row.codigo_postal }}
                                        </q-td>
                                        <q-td key="municipio_deudor" :props="props">
                                            {{ props.row.municipio_deudor }}
                                        </q-td>
                                        <q-td key="localidad" :props="props">
                                            {{ props.row.localidad }}
                                        </q-td>
                                        <q-td key="fraccionamiento_colonia" :props="props">
                                            {{ props.row.fraccionamiento_colonia }}
                                        </q-td>
                                        <q-td key="calle" :props="props">
                                            {{ props.row.calle }}
                                        </q-td>
                                        <q-td key="estatus" :props="props">
                                            <q-badge v-if="props.row.estatus == 'Pendiente'" color="blue">
                                                {{ props.row.estatus }}
                                            </q-badge>
                                            <q-badge v-else color="green">
                                                {{ props.row.estatus }}
                                            </q-badge>
                                        </q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </div>
    </q-layout>
</template>
<script>
import axios from 'axios'
import { QSpinnerGrid } from 'quasar'
import Deudor from './Deudor.vue'
export default {
    name: 'Home',
    components: {
        Deudor
    },
    data() {
        return {
            columns: [
                // { name: 'actions', align: 'center', label: 'Acciones', field: 'actions', sortable: true },
                {
                    name: 'nombre',
                    field: 'nombre',
                    required: true,
                    label: 'Nombre',
                    align: 'left',
                    sortable: true,
                    classes: 'bg-grey-2 ellipsis',
                    headerClasses: 'bg-primary text-white'
                },
                { name: 'rfc', align: 'left', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'placa', align: 'center', label: 'Placa', field: 'placa', sortable: true },
                { name: 'codigo_postal', align: 'center', label: 'Código Postal', field: 'codigo_postal', sortable: true },
                { name: 'municipio_deudor', align: 'left', label: 'Municipio', field: 'municipio_deudor', sortable: true },
                { name: 'localidad', align: 'left', label: 'Localidad', field: 'localidad', sortable: true },
                { name: 'fraccionamiento_colonia', align: 'left', label: 'Fraccionamiento - Colonia', field: 'fraccionamiento_colonia', sortable: true },
                { name: 'calle', align: 'left', label: 'Calle', field: 'calle', sortable: true },
            ],
            filter: '',
            loading: false,
            dialogNuevoDeudor: false,

            deudor: {
                nombre: '',
                curp: '',
                direccion: '',
                colonia: '',
                cp: '',
                telefono: ''
            },
            banderaEdit: false,
            estadoDedudor: 'Pendiente',
        }
    },
    computed: {
        data() {
            return this.$store.state.listaDeudoresStore
        },
    },
    created() {
    },
    methods: {
        async getListaDeudores() {

            if (this.$store.state.listaDeudoresStore.length != 0) {
                return
            }

            this.loading = true
            try {
                let response = await axios.get("Deudores/GetDeudores/" + this.estadoDedudor);
                this.$store.state.listaDeudoresStore = response.data.lista;
                this.loading = false
            } catch (error) {
                console.log(error);
                this.loading = false
            }
        },
        async putDeudor() {

        },
        nuevoDeudor() {
            this.banderaEdit = false
            this.dialogNuevoDeudor = true
            let deudor = {
                _id: "",
                tipo_placas: "",
                recaudacion: "",
                municipio: "",
                oficina: "",
                placa: "",
                nombre: "",
                rfc: "",
                fecha_registro: "",
                clave_vehicular: "",
                estatus_vehiculo: "",
                color: "",
                combustible: "",
                constancia: "",
                fecha_expedicion: "",
                marca: "",
                submarca: "",
                modelo: "",
                nrpv: "",
                numero_serie: "",
                reg_fed_vehi: "",
                tipo_vehi: "",
                version: "",
                tipo_transmision: "",
                uso_vehiculo: "",
                tipo_servicio: "",
                num_pasajeros: "",
                capacidad_carga: "",
                folio_regularizacion: "",
                tipo_persona: "",
                periodos_adeudo: "",
                ultimo_ejercicio_pagado: "",
                calle: "",
                fraccionamiento_colonia: "",
                localidad: "",
                municipio_deudor: "",
                codigo_postal: "",
                adeudos_sin_descuento: "",
                descuento: "",
                total_pagar_descuentos: "",
                estatus: 'Pendiente'
            }

            this.$store.state.deudorStore = { ...deudor }
        },

        editarDeudor(item) {
            this.deudor = item
            this.dialogNuevoDeudor = true
            this.banderaEdit = true
        },

    }
}
</script>