<template>
    <q-layout style="background: #f1f2f4" view="hHh lpR fFf">

        <q-dialog v-model="dialogTerminal" persistent>
            <q-card>
                <q-bar class="bg-primary text-white">
                    <q-icon color="white" name="mdi-cellphone-nfc" />
                    <div>Asignar Terminal</div>
                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section class="row items-center">
                    <div class="q-pt-md col-12">
                        <q-input dense style="width: 100%;" v-model="terminal" label="Terminal" filled />
                    </div>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                    <q-btn flat label="Actualizar" color="primary" @click="putUsuarioTerminal()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <q-dialog v-model="confirm" persistent>
            <q-card>
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-alert" color="primary" text-color="white" />
                    <span class="q-ml-sm">Estas seguro de cambiar el estatus del usuario {{ usuairoItem.usuario
                        }}?</span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                    <q-btn flat label="Cambiar estatus" color="primary" @click="putUsuario()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <q-dialog v-model="dialogNuevoUsuario" persistent>
            <q-card>
                <q-bar class="bg-primary text-white">
                    <q-icon color="white" name="mdi-account" />
                    <div>Nuevo usuario</div>
                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section class="q-col-gutter-sm">
                    <q-input filled dense v-model="nombre" placeholder="Nombre" label="Nombre"></q-input>
                    <q-input filled dense v-model="usuario" placeholder="Usuario" label="Usuario"></q-input>
                    <q-input filled dense v-model="pass" placeholder="Contraseña" label="Contraseña"
                        :type="isPwd ? 'password' : 'text'">
                        <template v-slot:append>
                            <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                                @click="isPwd = !isPwd" />
                        </template>
                    </q-input>
                    <div class="row ">
                        <div class="col-6 text-center" align="center">
                            <q-radio v-model="tipo" val="Movil" label="Movil"
                                style="display: flex; align-items: center;">
                                <q-icon style="font-size: 25px;" color="primary" name="mdi-cellphone" class="q-ml-lg" />
                            </q-radio>
                        </div>
                        <div class="col-6">
                            <q-radio v-model="tipo" val="Web" label="Web" style="display: flex; align-items: center;">
                                <q-icon style="font-size: 25px;" color="primary" name="mdi-laptop" class="q-ml-lg" />
                            </q-radio>
                        </div>
                    </div>

                </q-card-section>
                <q-card-actions align="right" class="text-right">
                    <q-btn flat color="primary" label="CERRAR" v-close-popup />
                    <q-btn flat color="primary" label="CREAR USUARIO" @click="postUsuario()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <div class="q-pa-xl q-mx-smq-gutter-sm">
            <q-card class="shadow-0" style="width: 100%;">
                <q-card-section full-width class=" text-white">
                    <div class="row">
                        <q-toolbar class="q-mb-md">
                            <q-btn color="primary" icon="mdi-plus" label="Nuevo usuario" @click="nuevoUsuario()" />
                            <q-space />

                            <q-btn round color="primary" icon="mdi-magnify" @click="getListaUsuarios()">
                                <q-tooltip>
                                    Actualizar Lista
                                </q-tooltip>
                            </q-btn>
                        </q-toolbar>
                        <div class="col-12 col-md-12">
                            <q-table dense :data="data" :columns="columns" row-key="name" title="Lista de Usuarios"
                                :filter="filter" :loading="loading" :pagination="{ rowsPerPage: 10 }">
                                <template v-slot:top-right>
                                    <q-input filled dense debounce="300" v-model="filter" placeholder="Buscar">
                                        <template v-slot:append>
                                            <q-icon name="search" />
                                        </template>
                                    </q-input>
                                </template>
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td key="actions" :props="props">
                                            <q-btn v-if="props.row.estatus == 'activo'" flat round color="red"
                                                icon="mdi-account-cancel-outline" @click="confirmEstatus(props.row)">
                                                <q-tooltip>
                                                    Desactivar
                                                </q-tooltip>
                                            </q-btn>

                                            <q-btn v-else flat round color="green" icon="mdi-account-check-outline"
                                                @click="confirmEstatus(props.row)">
                                                <q-tooltip>
                                                    Activar
                                                </q-tooltip>
                                            </q-btn>
                                            <q-btn v-if="props.row.tipo == 'Movil'" flat round color="purple"
                                                icon="mdi-cellphone-nfc" @click="confirmTerminal(props.row)">
                                                <q-tooltip>
                                                    Terminal
                                                </q-tooltip>
                                            </q-btn>
                                        </q-td>

                                        <q-td key="nombre" :props="props">
                                            {{ props.row.nombre }}
                                        </q-td>
                                        <q-td key="usuario" :props="props">
                                            {{ props.row.usuario }}
                                        </q-td>
                                        <q-td key="terminal" :props="props">
                                            {{ props.row.terminal }}
                                        </q-td>
                                        <q-td key="tipo" :props="props">
                                            {{ props.row.tipo }}
                                        </q-td>
                                        <q-td key="estatus" :props="props">
                                            <q-badge v-if="props.row.estatus == 'activo'" color="green">
                                                {{ props.row.estatus }}
                                            </q-badge>
                                            <q-badge v-else color="red">
                                                {{ props.row.estatus }}
                                            </q-badge>
                                        </q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </div>
    </q-layout>
</template>
<script>
import axios from 'axios'
import { QSpinnerGrid } from 'quasar'

export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
            columns: [
                { name: 'actions', align: 'center', label: 'Acciones', field: 'actions', sortable: true },
                {
                    name: 'nombre',
                    field: 'nombre',
                    required: true,
                    label: 'Nombre',
                    align: 'center',
                    sortable: true,
                    classes: 'bg-grey-2 ellipsis',
                    style: 'max-width: 100px',
                    headerClasses: 'bg-primary text-white'
                },
                { name: 'usuario', align: 'center', label: 'Usuario', field: 'usuario', sortable: true },
                { name: 'terminal', align: 'center', label: 'Terminal', field: 'terminal', sortable: true },
                { name: 'tipo', align: 'center', label: 'Tipo', field: 'tipo', sortable: true },
                { name: 'estatus', align: 'center', label: 'Estatus', field: 'estatus', sortable: true },
            ],
            data: [],
            filter: '',
            loading: false,
            dialogNuevoUsuario: false,

            pass: '',
            usuario: '',
            tipo: 'Movil',
            nombre: '',
            isPwd: true,
            confirm: false,
            usuairoItem: { usuario: '' },
            terminal: '',
            dialogTerminal: false
        }
    },
    created() {
        this.getListaUsuarios();
    },
    methods: {
        async getListaUsuarios() {
            this.loading = true
            try {
                let response = await axios.get("Usuarios/GetUsuarios");
                this.data = response.data.lista;
                this.loading = false
            } catch (error) {
                console.log(error);
                this.loading = false
            }
        },

        async postUsuario() {
            if (this.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre.' })
                return;
            }

            if (this.usuario == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el usuario.' })
                return;
            }

            if (this.pass == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Asigne una contraseña' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerGrid, spinnerColor: 'primary', spinnerSize: 120, message: 'Guardando usuario. Espere...', messageColor: 'white' })

            let objeto = {
                nombre: this.nombre,
                usuario: this.usuario,
                pass: this.pass,
                tipo: this.tipo,
                estatus: 'activo',
                correo: '',
                token: '',
                terminal: ''
            }

            try {
                let response = await axios.post("Usuarios/Create", objeto);
                console.log(response)
                this.$q.loading.hide()
                this.getListaUsuarios()
                this.dialogNuevoUsuario = false
                this.nombre = ''
                this.usuario = ''
                this.pass = ''
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()

            }
        },

        async putUsuario() {

            var estatus = 'activo'

            if (this.usuairoItem.estatus == 'activo') {
                estatus = 'inactivo'
            }

            let objeto = {
                id_usuario: this.usuairoItem.id_usuario,
                estado: estatus
            }

            this.$q.loading.show({ spinner: QSpinnerGrid, spinnerColor: 'primary', spinnerSize: 120, message: 'Actualizando usuario. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put("Usuarios/ActualizaEstado", objeto);
                console.log(response)
                this.$q.loading.hide()
                this.getListaUsuarios()
                this.confirm = false
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()

            }
        },

        async putUsuarioTerminal() {


            if (this.terminal == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la terminal que se asignara.' })
                return;
            }

            let objeto = {
                id_usuario: this.usuairoItem.id_usuario,
                terminal: this.terminal
            }

            this.$q.loading.show({ spinner: QSpinnerGrid, spinnerColor: 'primary', spinnerSize: 120, message: 'Asignando terminal al usuario. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put("Usuarios/PutTerminal", objeto);
                console.log(response)
                this.$q.loading.hide()
                this.getListaUsuarios()
                this.terminal = ""
                this.dialogTerminal = false
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        nuevoUsuario() {
            this.dialogNuevoUsuario = true
        },

        confirmEstatus(item, estatus) {
            this.usuairoItem = item
            this.confirm = true
        },
        confirmTerminal(item, estatus) {
            this.usuairoItem = item
            this.dialogTerminal = true
        }
    }
}
</script>