import jsPDF from 'jspdf';
import moment from 'moment'

import logo from '@/assets/logo-horizontal-removebg-preview.png';

function FormatoFecha(value) {
    moment.locale('es');
    return moment(value).format('DD-MMMM-YYYY')
}

function getBase64Image(imgUrl, callback) {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Esto es necesario si la imagen está en un dominio diferente
    img.src = imgUrl;
    img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        callback(dataURL);
    };
}

const ReporteVisitasPDF = async (items, fecha) => {
    return new Promise((resolve, reject) => {
        try {

            getBase64Image(logo, function (base64Image) {
                const doc = new jsPDF({
                    format: 'letter'
                });



                //CABECERA 
                let xCabecera = 5;
                let yCabecera = 15;
                let color = '#004d40'

                const widthT = 205;
                const heightT = 8;
                // Dibujar la barra de color
                doc.setFillColor(color);
                doc.rect(5, yCabecera + 4, widthT, heightT, 'F');
                doc.setTextColor("#fff");

                doc.setFontSize(10);
                doc.text('Placa', 7, yCabecera += 9, { align: 'left' });
                doc.text('Deudor', 25, yCabecera, { align: 'left' });
                doc.text('CP', 75, yCabecera, { align: 'left' });
                doc.text('Notificador', 85, yCabecera, { align: 'left' });
                doc.text('Viista', 125, yCabecera, { align: 'left' });
                doc.setFontSize(9);

                doc.text('Fecha Visita', 150, yCabecera, { align: 'left' });
                doc.setFontSize(10);

                doc.text('Estatus', 172, yCabecera, { align: 'left' });
                doc.text('Adeudo', 205, yCabecera, { align: 'right' });

                //RECORREMOS EL ARRAY DE LOS CONCEPTOS
                let yLista = yCabecera + 2;
                for (let c of items) {
                    //CLAVE DEL SAT
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(8);
                    doc.setTextColor("#000");

                    let placa = c.placa
                    let nombre = c.nombre
                    let codigoPostal = c.codigoPostal;
                    let nombreUsuario = c.nombreUsuario
                    let tipoVisita = c.tipoVisita
                    let fechaVisita = c.fecha_visita
                    let estatus = c.estatus
                    let total_pagar_descuentos = c.total_pagar_descuentos

                    doc.setFontSize(7);
                    doc.text(placa, 5, yLista += 5, { align: 'left' });
                    doc.text(codigoPostal, 75, yLista, { align: 'left' });
                    doc.text(tipoVisita, 125, yLista, { align: 'left' });
                    doc.text(fechaVisita, 150, yLista, { align: 'left' });
                    doc.text(estatus, 172, yLista, { align: 'left' });
                    doc.text(total_pagar_descuentos, 210, yLista, { align: 'right' });
                    // doc.setFontSize(8);



                    let nombre_ = doc.splitTextToSize(nombre, 70);
                    let nombre_Height = doc.getTextDimensions(nombre_).h;
                    doc.text(nombre_, 20, yLista, { align: 'left' });

                    let nombreUsuario_ = doc.splitTextToSize(nombreUsuario, 50);
                    let nombreUsuario_Height = doc.getTextDimensions(nombreUsuario_).h;
                    doc.text(nombreUsuario_, 85, yLista, { align: 'left' });

                    yLista += nombreUsuario_Height - 1

                    //INSERTMOS EL SALTO DE PAGINA
                    let espacioDisponible = doc.internal.pageSize.height - 40;
                    let dif = yLista > espacioDisponible

                    if (dif) {
                        doc.addPage();
                        yCabecera = 15;
                        doc.setFillColor(color);
                        doc.rect(5, yCabecera + 4, widthT, heightT, 'F');
                        doc.setTextColor("#fff");

                        doc.setFontSize(10);
                        doc.text('Placa', 7, yCabecera += 9, { align: 'left' });
                        doc.text('Deudor', 25, yCabecera, { align: 'left' });
                        doc.text('CP', 110, yCabecera, { align: 'left' });
                        doc.text('Adeudo', 130, yCabecera, { align: 'left' });
                        doc.text('Notificador', 150, yCabecera, { align: 'left' });
                        doc.text('Visita', 170, yCabecera, { align: 'left' });
                        doc.text('Estado', 190, yCabecera, { align: 'left' });
                        yLista = yCabecera + 2;
                    } else {
                        const color_ = "#662E91"
                        doc.setDrawColor(color_);
                        doc.setLineWidth(.1);
                        doc.line(5, yLista, 210, yLista);
                    }
                }

                //AGREGAMOS EL PIE DE PAGINA
                //AGREGAMOS EL PIE DE PAGINA
                var totalPages = doc.internal.getNumberOfPages();
                for (var i = 1; i <= totalPages; i++) {
                    doc.setPage(i);
                    let color = '#004d40'
                    doc.setTextColor(color);

                    doc.addImage(base64Image, 'PNG', 5, 3, 50, 15);
                    //DATOS DE LA EMPRESA Y POLIZA
                    doc.setFontSize(14);
                    doc.setFont('helvetica', 'bold');
                    doc.text('SISTEMA NOTIFICADOR', 210, 10, { align: 'right' });
                    let colors = '#000000'
                    doc.setTextColor(colors);
                    doc.setFontSize(10);
                    doc.text('Reporte de Visitas', 210, 14, { align: 'right' });
                    doc.setFontSize(9);
                    doc.text(fecha, 210, 18, { align: 'right' });


                    doc.setFont("helvetica", "italic");
                    doc.setFontSize(10);
                    doc.setTextColor(150);

                    // Agregar el contenido del pie de página
                    doc.text('Sistema Notificador by ContaGo', doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 10, { align: 'center' });
                    doc.text('Página ' + i + ' de ' + totalPages, 200, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
                }
                //EXPORTACION DE LA RECETA
                const base64 = doc.output('datauristring');
                let nombreDoc = 'nombreArchivo';
                // doc.save(nombreDoc + '.pdf');
                resolve(base64);
            });
        } catch (error) {
            console.log(error);
        }
    });
};

export { ReporteVisitasPDF };