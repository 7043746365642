<template>
    <q-layout style="background: #f1f2f4" view="hHh lpR fFf">

        <q-dialog v-model="dialogMapa" persistent>
            <q-card style="min-width:800px">
                <q-bar class="bg-primary text-white">
                    <q-icon color="white" name="mdi-map-marker-multiple" />
                    <div>Mapa</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <MapDialog :latitude="lat" :longitude="lng" />
                    <div class="row q-col-gutter-sm q-mt-sm">
                        <div class="col-12 col-md-4">
                            <q-input label="Usuario" filled dense v-model="evidencia.nombre" readonly />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input label="Fecha de visita" filled dense v-model="evidencia.fecha_visita" readonly />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input label="Georreferenciación" filled dense v-model="evidencia.georef" readonly />
                        </div>
                        <div class="col-12  ">
                            <q-input label="Notas" filled dense v-model="evidencia.notas" readonly />
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>

        <q-dialog v-model="dialogEvidencia" persistent>
            <q-card style="min-width:800px; ">
                <q-bar class="bg-primary text-white">
                    <q-icon color="white" name="mdi-map-marker-multiple" />
                    <div>Evidencias</div>
                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section>
                    <q-carousel style="min-width:600px; min-height: 600px;" swipeable animated v-model="slide"
                        thumbnails infinite :fullscreen.sync="fullscreen">
                        <q-carousel-slide :name="1" :img-src="imagen1" />
                        <q-carousel-slide :name="2" :img-src="imagen2" />
                        <q-carousel-slide :name="3" :img-src="imagen3" />
                        <q-carousel-slide :name="4" :img-src="imagen4" />
                        <q-carousel-slide :name="5" :img-src="imagen5" />
                        <!-- <q-carousel-slide
                            style="min-height: 100%; background-size: contain; background-position: center; background-repeat: no-repeat;"
                            :name="3" :img-src="imagen3" /> -->
                        <template v-slot:control>
                            <q-carousel-control position="bottom-right" :offset="[18, 18]">
                                <q-btn push round dense color="white" text-color="primary"
                                    :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                                    @click="fullscreen = !fullscreen" />
                            </q-carousel-control>
                        </template>
                    </q-carousel>
                    <div class="row q-col-gutter-sm q-mt-sm">
                        <div class="col-12 col-md-4">
                            <q-input label="Usuario" filled dense v-model="evidencia.nombre" readonly />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input label="Fecha de visita" filled dense v-model="evidencia.fecha_visita" readonly />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input label="Georreferenciación" filled dense v-model="evidencia.georef" readonly />
                        </div>
                        <div class="col-12  ">
                            <q-input label="Notas" filled dense v-model="evidencia.notas" readonly />
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>

        <!-- DIALOGO PARA CANCELAR VISITAS -->
        <q-dialog v-model="confirm" persistent>
            <q-card>
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-alert" color="primary" text-color="white" />
                    <span class="q-ml-sm">Estas seguro de cancelar la visita con el folio {{ itemVisita.folio }}?</span>

                    <div class="q-pt-md col-12">
                        <q-input dense style="width: 100%;" v-model="motivoCancelacion" label="Motivo de cancelación"
                            filled autogrow />
                    </div>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                    <q-btn flat label="Cancelar visita" color="primary" @click="putEstatusVisita()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOGO PARA RE ASIGNAR A OTRO USUARIO -->
        <q-dialog v-model="dialogAsignarVisita" persistent>
            <q-card>
                <q-bar class="bg-primary text-white">
                    <q-icon color="white" name="mdi-account" />
                    <div>Asginar Visita</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section class="q-col-gutter-sm">
                    <q-select dense filled v-model="model" use-input hide-selected fill-input input-debounce="0"
                        :options="filterUsuarios" @filter="filterFn" option-label="nombre" label="Nombre de usuario">
                        <template v-slot:no-option>
                            <q-item>
                                <q-item-section class="text-grey">
                                    No results
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-select>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                    <q-btn flat label="Asignar" color="primary" @click="postAsignar()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOGO PARA CREAR UNA NUEVA VISITA -->
        <q-dialog v-model="dialogNuevaVisita" persistent>
            <q-card style="min-width:1000px">
                <q-bar class="bg-primary text-white">
                    <q-icon color="white" name="mdi-map-marker-multiple" />
                    <div>Nueva Visita</div>
                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-8">
                            <q-select dense filled v-model="deudor" use-input hide-selected fill-input
                                input-debounce="0" :options="dataFilterDeudores" @filter="filterDeudoresNombre"
                                option-label="nombre" label="Nombre del deudor">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No results
                                        </q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:prepend>
                                    <q-icon name="mdi-magnify" />
                                </template>
                            </q-select>
                        </div>
                        <div class="col-12 col-md-4">
                            <q-select dense filled v-model="deudor" use-input hide-selected fill-input
                                input-debounce="0" :options="dataFilterDeudores" @filter="filterDeudoresCurp"
                                option-label="curp" label="Curp">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No results
                                        </q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:prepend>
                                    <q-icon name="mdi-magnify" />
                                </template>
                            </q-select>
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input label="Teléfono" filled dense v-model="deudor.telefono" readonly />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input label="CP" filled dense v-model="deudor.cp" readonly />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input label="Colonia" filled dense v-model="deudor.colonia" readonly />
                        </div>
                        <div class="col-12">
                            <q-input label="Dirección" filled dense v-model="deudor.direccion" readonly />
                        </div>
                        <div class="col-12">
                            <q-bar dense class="bg-primary text-white">
                                <q-space />
                                <div>TIPO DE VISITA Y FECHA DE VISITA</div>
                                <q-space />
                            </q-bar>
                        </div>
                        <div class="col-9">
                            <q-select label="Tipo de visita" :options="dataTipos" option-label="tipo" filled dense
                                v-model="tipovisita" />
                        </div>
                        <div class="col-3">
                            <q-input v-model="fehaIMasked" filled label="Fecha" name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="fecha_visita">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-input>
                        </div>
                        <!-- <div class="col-12">
                        <q-input label="Notas" filled dense v-model="notas"  />
                    </div> -->
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                    <q-btn flat label="Crear visita" color="primary" @click="postVisita()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALGOO PARA CREAR UN NUEVO TIPO DE VISITA -->
        <q-dialog v-model="dialogTiposVisita" persistent>
            <q-card>
                <q-bar class="bg-primary text-white">
                    <q-icon color="white" name="mdi-account" />
                    <div>Tipos de Visita</div>
                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section class="q-col-gutter-sm">
                    <q-input filled dense v-model="tipo" placeholder="Tipo de Visita" label="Tipo de visita"
                        @keyup.enter="postTipo()">
                        <template v-slot:after>
                            <q-btn color="green" round dense icon="mdi-plus" @click="postTipo()" />
                        </template>
                    </q-input>
                    <q-table class="shadow-0 q-mt-sm" dense :data="dataTipos" :columns="columnsTipos"
                        :pagination="{ rowsPerPage: 10 }" row-key="id_tipo_visita" :loading="loadingTipos"></q-table>

                </q-card-section>
            </q-card>
        </q-dialog>

        <!-- DIALGOO PARA ASIGNAR MASIVAMENTE  -->
        <q-dialog full-width v-model="dialogAsignacionMasiva" persistent>
            <q-card>
                <q-bar class="bg-primary text-white">
                    <q-icon color="white" name="mdi-account" />
                    <div>Asignar Visitas</div>
                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section class="q-col-gutter-sm">
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-3">
                            <q-select dense filled v-model="municipio" use-input hide-selected fill-input
                                input-debounce="0" :options="itemsFilterDeudoresMunicipios" @filter="filterMunicipios"
                                label="Filtrar por Municpio" @input="getListaLocalidad()">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No results
                                        </q-item-section>
                                    </q-item>
                                </template>

                                <template v-slot:prepend>
                                    <q-icon name="mdi-magnify" />
                                </template>
                            </q-select>
                        </div>
                        <div class="col-12 col-md-3">
                            <q-select dense filled v-model="localidad" use-input hide-selected fill-input
                                input-debounce="0" :options="itemsFilterDeudoresLocalidades" @filter="filterLocalidades"
                                label="Filtrar por localidad" @input="getListaColonias()" :loading="loadingL">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No results
                                        </q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:prepend>
                                    <q-icon name="mdi-magnify" />
                                </template>
                            </q-select>
                        </div>
                        <div class="col-12 col-md-3">
                            <q-select dense filled v-model="colonia" use-input hide-selected fill-input
                                input-debounce="0" :options="itemsFilterDeudoresColonias" @filter="filterColonias"
                                label="Filtrar por Colonia" :loading="loadingC">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No results
                                        </q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:prepend>
                                    <q-icon name="mdi-magnify" />
                                </template>
                                <template v-slot:after>
                                    <q-btn round dense color="primary" icon="mdi-magnify"
                                        @click="getListaDeudoresColonias()" />
                                </template>
                            </q-select>
                        </div>
                        <div class="col-12 col-md-3">
                            <q-select dense filled v-model="codigoPostal" use-input hide-selected fill-input
                                input-debounce="0" :options="itemsFilterDeudoresCodigos" @filter="filterCodigos"
                                label="Filtrar por Código Postal" :loading="loadingCP">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No results
                                        </q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:prepend>
                                    <q-icon name="mdi-magnify" />
                                </template>
                                <template v-slot:after>
                                    <q-btn round dense color="primary" icon="mdi-magnify"
                                        @click="getListaDeudoresCodigosPostales()" />
                                </template>
                            </q-select>
                        </div>
                        <div class="col-12">
                            <q-bar dense class="bg-primary text-white">
                                <q-space />
                                <div>DATOS DE LA VISITA</div>
                                <q-space />
                            </q-bar>
                        </div>
                        <div class="col-12 col-md-4">
                            <q-select label="Tipo de visita" :options="dataTipos" option-label="tipo" filled dense
                                v-model="tipovisita" />
                        </div>
                        <div class="col-md-3 col-12">
                            <q-input v-model="fehaIMasked" filled label="Fecha" name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="fecha_visita">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-input>
                        </div>
                        <div class="col-12 col-md-5">
                            <q-select dense filled v-model="model" use-input hide-selected fill-input input-debounce="0"
                                :options="filterUsuarios" @filter="filterFn" option-label="nombre"
                                label="Nombre de usuario">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No results
                                        </q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:after>
                                    <q-btn round dense color="primary" icon="mdi-send" @click="postAsignarMasivo()" />
                                </template>
                            </q-select>
                        </div>
                    </div>
                    <q-table class="q-mt-sm" dense :data="itemsDeudoresColonias" :columns="columnsDeudores"
                        row-key="_id" title="Lista de Deudores" :filter="filterDeudores" :loading="loadingDeudores"
                        :pagination="{ rowsPerPage: 10 }" selection="multiple" :selected.sync="deudoresSeleccionados">
                        <template v-slot:top-right>
                            <q-input filled dense debounce="300" v-model="filter" placeholder="Buscar">
                                <template v-slot:append>
                                    <q-icon name="search" />
                                </template>
                            </q-input>
                        </template>
                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td auto-width>
                                    <q-checkbox v-model="props.selected"></q-checkbox>
                                </q-td>
                                <q-td key="nombre" :props="props">
                                    {{ props.row.nombre }}
                                </q-td>
                                <q-td key="rfc" :props="props">
                                    {{ props.row.rfc }}
                                </q-td>
                                <q-td key="placa" :props="props">
                                    {{ props.row.placa }}
                                </q-td>
                                <q-td key="codigo_postal" :props="props">
                                    {{ props.row.codigo_postal }}
                                </q-td>
                                <q-td key="municipio_deudor" :props="props">
                                    {{ props.row.municipio_deudor }}
                                </q-td>
                                <q-td key="localidad" :props="props">
                                    {{ props.row.localidad }}
                                </q-td>
                                <q-td key="fraccionamiento_colonia" :props="props">
                                    {{ props.row.fraccionamiento_colonia }}
                                </q-td>
                                <q-td key="calle" :props="props">
                                    {{ props.row.calle }}
                                </q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </q-card-section>
            </q-card>
        </q-dialog>

        <div class="q-pa-xl q-mx-smq-gutter-sm">
            <q-card class="shadow-0" style="width: 100%;">
                <q-card-section full-width class=" text-white">
                    <div class="row">
                        <q-toolbar class="q-mb-md">
                            <!-- <q-btn color="primary" icon="mdi-plus" label="Nueva visita" @click="nuevaVisita()" /> -->
                            <q-btn color="primary" icon="mdi-plus" label="Asignación Masiva"
                                @click="dialogAsignacionMasiva = true" />
                            <q-btn class="q-ml-sm" round color="primary" icon="mdi-cog" @click="TiposDeVisita()">
                                <q-tooltip>
                                    Tipos de Visita
                                </q-tooltip>
                            </q-btn>
                            <q-space />
                            <q-select dense filled v-model="estadoVisita" style="width:300px" fill-input
                                :options="['Asignado', 'Visitado', 'Pagado', 'Cancelada']"
                                label="Consultar por Estatus">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No results
                                        </q-item-section>
                                    </q-item>
                                </template>

                                <template v-slot:after>
                                    <q-btn round color="primary" icon="mdi-magnify" @click="getListaVisitasGeneral()">
                                        <q-tooltip>
                                            Actualizar Lista
                                        </q-tooltip>
                                    </q-btn>
                                </template>
                            </q-select>
                        </q-toolbar>
                        <div class="col-12 col-md-12">
                            <q-table dense :data="data" :columns="columns" row-key="name"
                                title="Lista de Visitas creadas" :filter="filter" :loading="loading"
                                :pagination="{ rowsPerPage: 10 }">
                                <template v-slot:top-right>
                                    <q-input borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                                        <template v-slot:append>
                                            <q-icon name="search" />
                                        </template>
                                    </q-input>
                                </template>
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td key="actions" :props="props">
                                            <q-btn v-if="props.row.estatus == 'Asignado'" flat round color="green"
                                                icon="mdi-account-arrow-right-outline"
                                                @click="asignarVisita(props.row)">
                                                <q-tooltip>
                                                    Re-Asignar
                                                </q-tooltip>
                                            </q-btn>
                                            <q-btn v-if="props.row.estatus != 'Cancelada'" flat round color="red"
                                                icon="mdi-delete-empty" @click="eliminarVisita(props.row)">
                                                <q-tooltip>
                                                    Eliminar
                                                </q-tooltip>
                                            </q-btn>
                                            <q-btn
                                                v-if="props.row.estatus == 'Visitado' || props.row.estatus == 'Pagado'"
                                                flat round color="purple" icon="mdi-map-search"
                                                @click="verMapa(props.row)">
                                                <q-tooltip>
                                                    Ver Mapa
                                                </q-tooltip>
                                            </q-btn>
                                            <q-btn
                                                v-if="props.row.estatus == 'Visitado' || props.row.estatus == 'Pagado'"
                                                flat round color="blue" icon="mdi-image-marker-outline"
                                                @click="verEvidencia(props.row)">
                                                <q-tooltip>
                                                    Ver Evidencia
                                                </q-tooltip>
                                            </q-btn>
                                            <!-- <q-btn  flat round color="blue"
                                                icon="mdi-image-marker-outline" @click="obtenerCoordenadas()">
                                                <q-tooltip>
                                                    Ver Georreferenciación
                                                </q-tooltip>
                                            </q-btn> -->
                                        </q-td>
                                        <q-td key="folio" :props="props">
                                            {{ props.row.folio }}
                                        </q-td>
                                        <q-td key="nombre" :props="props">
                                            {{ props.row.deudor.nombre }}
                                        </q-td>
                                        <q-td key="tipovisita" :props="props">
                                            {{ props.row.tipo_visita }}
                                        </q-td>
                                        <q-td key="codigo_postal" :props="props">
                                            {{ props.row.deudor.codigo_postal }}
                                        </q-td>
                                        <q-td key="municipio_deudor" :props="props">
                                            {{ props.row.deudor.municipio_deudor }}
                                        </q-td>
                                        <q-td key="localidad" :props="props">
                                            {{ props.row.deudor.localidad }}
                                        </q-td>
                                        <q-td key="fraccionamiento_colonia" :props="props">
                                            {{ props.row.deudor.fraccionamiento_colonia }}
                                        </q-td>
                                        <q-td key="calle" :props="props">
                                            {{ props.row.deudor.calle }}
                                        </q-td>
                                        <q-td key="fecha_visita" :props="props">
                                            {{ props.row.fecha_visita }}
                                        </q-td>
                                        <q-td key="usuario" :props="props">
                                            {{ props.row.usuario.usuario }}
                                        </q-td>
                                        <q-td key="estatus" :props="props">
                                            <q-badge v-if="props.row.estatus == 'Asignado'" color="purple">
                                                {{ props.row.estatus }}
                                            </q-badge>
                                            <q-badge v-else-if="props.row.estatus == 'visitado'" color="green">
                                                {{ props.row.estatus }}
                                            </q-badge>
                                            <q-badge v-else-if="props.row.estatus == 'Cancelada'" color="red">
                                                {{ props.row.estatus }}
                                            </q-badge>
                                            <q-badge v-else color="blue">
                                                {{ props.row.estatus }}
                                            </q-badge>
                                        </q-td>
                                        <q-td key="notas" :props="props">
                                            {{ props.row.notas }}
                                        </q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </div>
    </q-layout>
</template>
<script>
import axios from 'axios'
import { QSpinnerGrid } from 'quasar'
import moment from 'moment';
import MapDialog from '../Mapa/Mapa.vue';
export default {
    name: 'Home',
    components: {
        MapDialog
    },
    data() {
        return {
            columns: [
                { name: 'actions', align: 'center', label: 'Acciones', field: 'actions', sortable: true },
                {
                    name: 'folio',
                    field: 'folio',
                    required: true,
                    label: 'Folio',
                    align: 'center',
                    sortable: true,
                    classes: 'bg-grey-2 ellipsis',
                    style: 'max-width: 100px',
                    headerClasses: 'bg-primary text-white'
                },
                { name: 'nombre', align: 'left', label: 'Deudor', field: 'nombre', sortable: true },
                { name: 'tipovisita', align: 'center', label: 'Tipo de Visita', field: 'tipovisita', sortable: true },
                { name: 'codigo_postal', align: 'center', label: 'Código Postal', field: 'codigo_postal' },
                { name: 'municipio_deudor', align: 'center', label: 'Municipio', field: 'municipio_deudor' },
                { name: 'localidad', align: 'center', label: 'Localidad', field: 'localidad' },
                { name: 'fraccionamiento_colonia', align: 'center', label: 'Fraccionamiento o Colonia', field: 'fraccionamiento_colonia' },
                { name: 'calle', align: 'center', label: 'Calle', field: 'calle' },
                { name: 'fecha_visita', align: 'center', label: 'Fecha de Visita', field: 'fecha_visita' },
                { name: 'usuario', align: 'center', label: 'Usuario', field: 'usuario' },
                { name: 'estatus', align: 'center', label: 'Estatus', field: 'estatus' },
                { name: 'notas', align: 'center', label: 'Notas', field: 'notas' },
            ],
            data: [],
            filter: '',
            loading: false,
            tipo: '',
            dialogTiposVisita: false,

            columnsTipos: [
                {
                    name: 'tipo',
                    field: 'tipo',
                    required: true,
                    label: 'Tipo',
                    align: 'center',
                    sortable: true,
                    classes: 'bg-grey-2 ellipsis',
                    style: 'max-width: 100px',
                    headerClasses: 'bg-primary text-white'
                }],
            dataTipos: [],
            loadingTipos: false,
            confirm: false,
            dialogAsignarVisita: false,
            usuarios: [],
            filterUsuarios: [],
            itemVisita: { folio: '' },
            motivoCancelacion: '',

            dialogNuevaVisita: false,
            dataDeudores: [],
            dataFilterDeudores: [],
            deudor: { nombre: '', curp: '' },
            fecha_visita: new Date(),
            // notas:''
            dialogMapa: false,
            dialogEvidencia: false,
            slide: 1,
            imagen1: '',
            imagen2: '',
            imagen3: '',
            imagen4: '',
            imagen5: '',
            fullscreen: false,
            lat: 0,
            lng: 0,

            evidencia: {
                nombre: '',
                fecha_visita: '',
                georef: '',
                notas: ''
            },

            dialogAsignacionMasiva: false,
            filterDeudores: '',
            loadingDeudores: false,
            columnsDeudores: [
                { name: 'nombre', field: 'nombre', required: true, label: 'Nombre', align: 'left', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'rfc', align: 'left', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'placa', align: 'center', label: 'Placa', field: 'placa', sortable: true },
                { name: 'codigo_postal', align: 'center', label: 'Código Postal', field: 'codigo_postal', sortable: true },
                { name: 'municipio_deudor', align: 'left', label: 'Municipio', field: 'municipio_deudor', sortable: true },
                { name: 'localidad', align: 'left', label: 'Localidad', field: 'localidad', sortable: true },
                { name: 'fraccionamiento_colonia', align: 'left', label: 'Fraccionamiento - Colonia', field: 'fraccionamiento_colonia', sortable: true },
                { name: 'calle', align: 'left', label: 'Calle', field: 'calle', sortable: true },
            ],
            deudoresSeleccionados: [],
            municipio: '',
            localidad: '',
            colonia: '',
            codigoPostal: '',
            itemsColonias: [],
            itemsMunicipio: [],
            itemsLocalidad: [],
            itemsCodigoPostal: [],
            itemsFilterDeudoresColonias: [],
            itemsFilterDeudoresMunicipios: [],
            itemsFilterDeudoresLocalidades: [],
            itemsFilterDeudoresCodigos: [],

            itemsDeudoresColonias: [],

            tipovisita: { tipo: '' },
            model: { nombre: '' },
            estadoVisita: 'Asignado',
            loadingL: false,
            loadingC: false,
            loadingCP: false,
        }
    },
    computed: {
        itemsDeudores() {
            return this.$store.state.listaDeudoresStore
        },
        itemsUsuarios() {
            return this.usuarios.filter(x => x.estatus == 'activo' && x.tipo == 'Movil');
        },
        token() {
            return this.$store.state.usuario;
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.fecha_visita ? moment.utc(this.fecha_visita).format('DD/MMMM/yyyy') : ''
        },
    },
    created() {
        this.getListaVisitasGeneral();
        this.getListaTiposVisita();
        this.getListaUsuarios();
        this.getListaMunicipio();
        this.getListaCodigoPostal();
    },
    methods: {
        async getListaVisitasGeneral() {
            this.loading = true
            try {
                let response = await axios.get("Visita/GetVisitasXEstatus/" + this.estadoVisita);
                this.data = response.data;
                this.loading = false
            } catch (error) {
                console.log(error);
                this.loading = false
            }
        },

        async getListaTiposVisita() {
            this.loadingTipos = true
            try {
                let response = await axios.get("TipoVisita/GetTipoVisita");
                this.dataTipos = response.data.lista;
                this.loadingTipos = false
            } catch (error) {
                console.log(error);
                this.loadingTipos = false
            }
        },

        async postTipo() {
            if (this.tipo == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre.' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerGrid, spinnerColor: 'primary', spinnerSize: 120, message: 'Creando un nuevo tipo de visita. Espere...', messageColor: 'white' })

            let objeto = {
                tipo: this.tipo,
            }

            try {
                let response = await axios.post("TipoVisita/Create", objeto);
                console.log(response)
                this.$q.loading.hide()
                this.getListaTiposVisita()
                this.tipo = ''
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        async getListaUsuarios() {
            try {
                let response = await axios.get("Usuarios/GetUsuarios");
                this.usuarios = response.data.lista;
            } catch (error) {
                console.log(error);
            }
        },

        async getListaColonias() {
            this.itemsColonias = []
            this.loadingC = true

            try {
                let response = await axios.get("Deudores/GetColoniasDeudores/" + this.localidad);
                this.itemsColonias = response.data;
                this.loadingC = false
            } catch (error) {
                console.log(error);
                this.loadingC = false

            }
        },
        async getListaMunicipio() {
            this.itemsMunicipio = []
            this.colonia = ""
            try {
                let response = await axios.get("Deudores/GetMunicipiosDeudores");
                this.itemsMunicipio = response.data;
                this.localidad = ""

            } catch (error) {
                console.log(error);
            }
        },
        async getListaLocalidad() {
            this.loadingL = true
            this.colonia = ''
            this.itemsLocalidad = []
            try {
                let response = await axios.get("Deudores/GetLocalidadesDeudores/" + this.municipio);
                this.itemsLocalidad = response.data;
                this.loadingL = false

            } catch (error) {
                console.log(error);
                this.loadingL = false
            }
        },
        async getListaCodigoPostal() {
            this.itemsCodigoPostal = []
            this.loadingCP = true
            try {
                let response = await axios.get("Deudores/GetCodigPostalDeudores");
                this.itemsCodigoPostal = response.data;
                this.loadingCP = false
            } catch (error) {
                this.loadingCP = false
                console.log(error);
            }
        },

        async getListaDeudoresColonias() {
            console.log(this.colonia)
            this.loadingDeudores = true
            this.itemsDeudoresColonias = []

            try {
                let response = await axios.get("Deudores/GetDeudoresXColonia/" + this.municipio + '/' + this.localidad + '/' + this.colonia);
                this.itemsDeudoresColonias = response.data;
                this.loadingDeudores = false

            } catch (error) {
                console.log(error);
                this.loadingDeudores = false

            }
        },

        async getListaDeudoresCodigosPostales() {
            console.log(this.colonia)
            this.loadingDeudores = true
            this.itemsDeudoresColonias = []

            try {
                let response = await axios.get("Deudores/GetDeudoresXCodigoPostal/" + this.codigoPostal);
                this.itemsDeudoresColonias = response.data;
                this.loadingDeudores = false

            } catch (error) {
                console.log(error);
                this.loadingDeudores = false

            }
        },

        async postAsignar() {
            if (this.model.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione el usuario al que se le asignara la visita.' })
                return;
            }
            console.log(this.model)
            console.log(this.itemVisita)
            this.$q.loading.show({ spinner: QSpinnerGrid, spinnerColor: 'primary', spinnerSize: 120, message: 'Asignando visita. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put("Visita/PutUsuarioVisita/" + this.itemVisita._id, this.model);
                console.log(response)
                this.$q.loading.hide()
                this.getListaVisitasGeneral()
                this.dialogAsignarVisita = false
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        async putEstatusVisita() {
            if (this.motivoCancelacion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el motivo de cancelación.' })
                return;
            }
            console.log(this.model)
            console.log(this.itemVisita)
            this.$q.loading.show({ spinner: QSpinnerGrid, spinnerColor: 'primary', spinnerSize: 120, message: 'Asignando visita. Espere...', messageColor: 'white' })

            let objeto = {
                id_visita: this.itemVisita._id,
                id_usuario: this.token.id_usuario,
                accion: this.motivoCancelacion,
                estado: "Cancelada"
            }
            console.log(objeto)
            try {
                let response = await axios.put("Visita/PutEstatusVisita", objeto);
                console.log(response)
                this.$q.loading.hide()
                this.getListaVisitasGeneral()
                this.confirm = false
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        async postVisita() {
            let fechaVisita = moment(this.fecha_visita).format('YYYY-MM-DD')
            if (this.deudor.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione el nombre del deudor.' })
                return;
            }

            if (this.tipovisita.tipo == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione el tipo de visita.' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerGrid, spinnerColor: 'primary', spinnerSize: 120, message: 'Creando un nuevo tipo de visita. Espere...', messageColor: 'white' })

            let objeto = {
                id_deudor: this.deudor.id_deudor,
                id_tipo_visita: this.tipovisita.id_tipo_visita,
                fecha_visita: fechaVisita,
                // notas:this.notas
            }

            try {
                let response = await axios.post("Visita/Create", objeto);
                console.log(response)
                this.$q.loading.hide()

                this.getListaVisitasGeneral()
                this.deudor = { nombre: '', curp: '' }
                this.tipovisita = { tipo: '' }
                this.fecha_visita = new Date()
                // this.notas=''

            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }

        },

        asignarVisita(item) {
            this.itemVisita = item
            this.dialogAsignarVisita = true
        },

        async nuevaVisita() {
            await this.getListaTiposVisita();
            this.dialogNuevaVisita = true
        },

        eliminarVisita(item) {
            this.itemVisita = item
            this.confirm = true
        },

        TiposDeVisita() {
            this.getListaTiposVisita()
            this.dialogTiposVisita = true
        },

        async verMapa(item) {
            console.log(item)
            this.$q.loading.show({ spinner: QSpinnerGrid, spinnerColor: 'primary', spinnerSize: 120, message: 'Obteniendo evidencia. Espere...', messageColor: 'white' })
            try {
                let response = await axios.get("Visita/GetVisitaDetXIdVisita/" + item._id);
                console.log(response)
                this.$q.loading.hide()
                const partes = response.data.georef.split(" ");

                this.evidencia.nombre = item.usuario.nombre
                this.evidencia.fecha_visita = response.data.fecha_visita
                this.evidencia.georef = response.data.georef
                this.evidencia.notas = response.data.notas

                this.lat = parseFloat(partes[0]);
                this.lng = parseFloat(partes[2]);

                this.dialogMapa = true

            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        async verEvidencia(item) {
            this.$q.loading.show({ spinner: QSpinnerGrid, spinnerColor: 'primary', spinnerSize: 120, message: 'Obteniendo evidencia. Espere...', messageColor: 'white' })
            try {
                let response = await axios.get("Visita/GetVisitaDetXIdVisita/" + item._id);
                console.log(response)
                this.$q.loading.hide()
                this.imagen1 = 'data:image/jpeg;base64,' + response.data.foto1
                this.imagen2 = 'data:image/jpeg;base64,' + response.data.foto2
                this.imagen3 = 'data:image/jpeg;base64,' + response.data.foto3
                this.imagen4 = 'data:image/jpeg;base64,' + response.data.foto4
                this.imagen5 = 'data:image/jpeg;base64,' + response.data.foto5
                this.evidencia.nombre = item.usuario.nombre
                this.evidencia.fecha_visita = response.data.fecha_visita
                this.evidencia.georef = response.data.georef
                this.evidencia.notas = response.data.notas
                this.dialogEvidencia = true

            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }

        },

        filterFn(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.filterUsuarios = this.itemsUsuarios.filter(v => v.nombre.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.filterUsuarios.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },

        filterColonias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFilterDeudoresColonias = this.itemsColonias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFilterDeudoresColonias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },

        filterMunicipios(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFilterDeudoresMunicipios = this.itemsMunicipio.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFilterDeudoresMunicipios.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },
        filterLocalidades(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFilterDeudoresLocalidades = this.itemsLocalidad.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFilterDeudoresLocalidades.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },
        filterCodigos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFilterDeudoresCodigos = this.itemsCodigoPostal.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFilterDeudoresCodigos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },

        filterDeudoresNombre(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.dataFilterDeudores = this.itemsDeudores.filter(v => v.nombre.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.dataFilterDeudores.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },

        filterDeudoresCurp(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.dataFilterDeudores = this.dataDeudores.filter(v => v.curp.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.dataFilterDeudores.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                }
            )
        },

        async postAsignarMasivo() {
            if (this.model.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione el nombre del usuario.' })
                return;
            }

            if (this.tipovisita.tipo == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione el tipo de visita.' })
                return;
            }

            if (this.deudoresSeleccionados == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione al menos un deudor para poder crear la visita.' })
                return;
            }

            let listaVisitas = []
            for (let a of this.deudoresSeleccionados) {

                let objeto = {
                    _id: '',
                    folio: '',
                    deudor: a,
                    usuario: this.model,
                    estatus: 'Asignado',
                    tipo_visita: this.tipovisita.tipo,
                    notas: '',
                    fecha_visita: moment(this.fecha_visita).format('YYYY-MM-DD'),
                }
                listaVisitas.push(objeto)
            }

            console.log(listaVisitas)

            this.$q.loading.show({ spinner: QSpinnerGrid, spinnerColor: 'primary', spinnerSize: 120, message: 'Asignando las visitas. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post("Visita/CreateMasivo", listaVisitas);
                console.log(response)
                this.$q.loading.hide()

                this.tipovisita = { tipo: '' }
                this.fecha_visita = new Date();
                this.deudoresSeleccionados = []
                listaVisitas: []
                this.itemsDeudoresColonias = []

            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },
        async obtenerCoordenadas() {
            let direccion = "1600 Amphitheatre Parkway, Mountain View, CA"
            const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(direccion)}&format=json&limit=1`;

            try {
                const respuesta = await axios.get(url);
                const datos = respuesta.data;

                if (datos.length > 0) {
                    const ubicacion = datos[0];
                    console.log(`Latitud: ${ubicacion.lat}, Longitud: ${ubicacion.lon}`);
                } else {
                    console.error('No se encontraron resultados para la dirección proporcionada.');
                }
            } catch (error) {
                console.error('Error al hacer la solicitud:', error);
            }
        }
    }
}
</script>