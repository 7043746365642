import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'
import Login from '../views/Login.vue'
import MainPage from '../components/Main/MainPage.vue'
import ListaUsuarios from '../components/Main/Usuarios/ListaUsuarios.vue'
import ListaVisitas from '../components/Main/Visitas/ListaVisitas.vue'
import ListaDeudores from '../components/Main/Deudores/ListaDeudores.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', component: Home, name: 'Home', meta: { Administrador: true },

  },
  {
    path: '/MainPage', component: MainPage, name: 'MainPage', meta: { Administrador: true },
    children: [
      { path: 'ListaUsuarios', component: ListaUsuarios, name: 'ListaUsuarios', meta: { Administrador: true } },
      { path: 'ListaVisitas', component: ListaVisitas, name: 'ListaVisitas', meta: { Administrador: true } },
      { path: 'ListaDeudores', component: ListaDeudores, name: 'ListaDeudores', meta: { Administrador: true } },
    ],
  },
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.tipo == 'Web') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  }
  else {
    next({
      name: 'Login'
    })
  }
})

export default router
