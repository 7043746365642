<template>
    <q-card>
        <q-bar class="bg-primary text-white">
            <q-icon color="white" name="mdi-account" />
            <div>Deudor</div>
            <q-space />

            <q-btn dense flat icon="close" v-close-popup>
                <q-tooltip>Close</q-tooltip>
            </q-btn>
        </q-bar>

        <q-card-section class="q-col-gutter-sm">
            <div class="row q-col-gutter-sm">
                <div class="col-12">
                    <q-bar dense class="bg-primary text-white">
                        <q-space />
                        <div>DATOS DEL VEHICULO</div>
                        <q-space />
                    </q-bar>
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Tipo de Placas" filled dense v-model="deudor.tipo_placas" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Placa" filled dense v-model="deudor.placa" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Clave Vehicular" filled dense v-model="deudor.clave_vehicular" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Estatus del Vehiculo" filled dense v-model="deudor.estatus_vehiculo" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Color" filled dense v-model="deudor.color" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Combustible" filled dense v-model="deudor.combustible" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Recaudación" filled dense v-model="deudor.recaudacion" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Marca" filled dense v-model="deudor.marca" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="SubMarca" filled dense v-model="deudor.submarca" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Modelo" filled dense v-model="deudor.modelo" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="NRPV" filled dense v-model="deudor.nrpv" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Número de Serie" filled dense v-model="deudor.numero_serie" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Registro Federal del Vehiculo" filled dense v-model="deudor.reg_fed_vehi" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Tipo de Vehiculo" filled dense v-model="deudor.tipo_vehiculo" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Tipo de Vehiculo" filled dense v-model="deudor.version" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Tipo de Vehiculo" filled dense v-model="deudor.tipo_transmision" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Tipo de Servicio" filled dense v-model="deudor.tipo_servicio" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Núm. de Pasajeros" filled dense v-model="deudor.num_pasajeros" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Capacidad de Carga" filled dense v-model="deudor.capacidad_carga" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Folio de Regularización" filled dense v-model="deudor.folio_regularizacion" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Municipio" filled dense v-model="deudor.municipio" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Oficina" filled dense v-model="deudor.oficina" />
                </div>
                <div class="col-12">
                    <q-bar dense class="bg-primary text-white">
                        <q-space />
                        <div>DATOS DE LA PERSONA</div>
                        <q-space />
                    </q-bar>
                </div>


                <div class="col-12 col-md-6">
                    <q-input label="Nombre" filled dense v-model="deudor.nombre" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="RFC" filled dense v-model="deudor.rfc" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Fecha de Registro" filled dense v-model="deudor.fecha_registro" />
                </div>

                <div class="col-12 col-md-3">
                    <q-input label="Estatus del Vehiculo" filled dense v-model="deudor.constancia" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Estatus del Vehiculo" filled dense v-model="deudor.fecha_expedicion" />
                </div>

                <div class="col-12 col-md-3">
                    <q-input label="Tipo de Persona" filled dense v-model="deudor.tipo_persona" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Periodos de Adeudos" filled dense v-model="deudor.periodos_adeudo" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Ultimo ejercicio pagado" filled dense v-model="deudor.ultimo_ejercicio_pagado" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Calle" filled dense v-model="deudor.calle" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Fraccionamiento - Colonia" filled dense v-model="deudor.fraccionamiento_colonia" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Localidad" filled dense v-model="deudor.localidad" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Municipio del Deudor" filled dense v-model="deudor.municipio_deudor" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Código Postal" filled dense v-model="deudor.codigo_postal" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Adeudos sin descuento" filled dense v-model="deudor.adeudos_sin_descuento" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Descuento" filled dense v-model="deudor.descuento" />
                </div>
                <div class="col-12 col-md-3">
                    <q-input label="Total a pagar con descuento" filled dense v-model="deudor.total_pagar_descuentos" />
                </div>

            </div>
        </q-card-section>
        <q-card-actions align="right" class="text-right">
            <q-btn flat label="Cerrar" color="primary" v-close-popup />
            <q-btn v-if="deudor._id == ''" flat color="primary" label="CREAR DEUDOR" @click="postDeudor()" />
            <q-btn v-else flat color="primary" label="EDITAR DEUDOR" @click="putDeudor()" />
        </q-card-actions>
    </q-card>
</template>
<script>
import axios from 'axios'
import { QSpinnerGrid } from 'quasar'

export default {
    components: {
    },
    data() {
        return {

        }
    },
    computed: {
        deudor() {
            return this.$store.state.deudorStore;
        },
    },
    watch: {
    },
    created() {
    },
    methods: {
        async postDeudor() {
            // if (this.deudor.NOMBRE == '') {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del deudor.' })
            //     return;
            // }

            this.$q.loading.show({ spinner: QSpinnerGrid, spinnerColor: 'primary', spinnerSize: 120, message: 'Guardando deudor. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post("Deudores/Create", this.deudor);
                console.log(response)
                this.$q.loading.hide()

                this.inicializarObjeto();

            } catch (error) {
                console.log(error);
                this.$q.loading.hide()

            }
        },

        inicializarObjeto() {
            let deudor = {
                id_deudor: "",
                TIPO_PLACAS: "",
                RECAUDACION: "",
                MUNICIPIO: "",
                OFICINA: "",
                PLACA: "",
                NOMBRE: "",
                RFC: "",
                FECHA_REGISTRO: "",
                CLAVE_VEHICULAR: "",
                ESTATUS_VEHICULO: "",
                COLOR: "",
                COMBUSTIBLE: "",
                CONSTANCIA: "",
                FECHA_EXPEDICION: "",
                MARCA: "",
                SUBMARCA: "",
                MODELO: "",
                NRPV: "",
                NUMERO_SERIE: "",
                REG_FED_VEHI: "",
                TIPO_VEHICULO: "",
                VERSION: "",
                TIPO_TRANSMISION: "",
                USO_VEHICULO: "",
                TIPO_SERVICIO: "",
                NUM_PASAJEROS: "",
                CAPACIDAD_CARGA: "",
                FOLIO_REGULARIZACION: "",
                TIPO_PERSONA: "",
                Periodos_de_Adeudo: "",
                Ultimo_ejercicio_pagado: "",
                CALLE: "",
                FRACCIONAMIENTO_COLONIA: "",
                LOCALIDAD: "",
                MUNICIPIO_deudor: "",
                CODIGO_POSTAL: ""
            }

            this.$store.state.deudorStore = { ...deudor }
        }
    }
}
</script>
<style></style>