<template>
    <q-layout view="hHh lpR fFf">
        <q-header elevated class="bg-dark">
            <q-toolbar class="no-shadow bg-dark q-pa-sm q-px-xl">
                <!-- <q-btn flat round dense icon="home" @click="irInicio()" /> -->
                <img class="q-mt-sm" src="../../assets/image.png" width="200" alt="" @click="irInicio()"
                    style="cursor: pointer;" />
                <q-space />
                <q-btn flat round>
                    <q-avatar color="grey" text-color="dark">{{ letra }}</q-avatar>
                    <q-menu>
                        <q-list style="min-width: 100px">
                            <q-item clickable v-close-popup @click="salir()">
                                <q-item-section avatar>
                                    <q-icon color="dark" name="mdi-logout" />
                                </q-item-section>
                                <q-item-section>Cerrar Sesión</q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn>
                <!-- <q-btn flat round class="q-pa-md">
                    <q-avatar color="white" text-color="primary">A</q-avatar>
                    <q-menu>
                        <q-list style="min-width: 100px">
                            <q-item clickable v-close-popup @click="salir()">
                                <q-item-section avatar>
                                    <q-icon color="primary" name="mdi-logout" />
                                </q-item-section>

                                <q-item-section>Cerrar Sesión</q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn> -->
            </q-toolbar>

            <q-toolbar inset>
                <q-breadcrumbs active-color="white" style="font-size: 16px">
                    <q-breadcrumbs-el label="Usuarios" icon="mdi-account-group" @click="irUsuarios()"
                        style="cursor: pointer;" />
                    <q-breadcrumbs-el label="Visitas" icon="mdi-map-marker-multiple" @click="irVisitas()"
                        style="cursor: pointer;" />
                    <q-breadcrumbs-el label="Deudores" icon="mdi-account-multiple-remove" @click="irDeudores()"
                        style="cursor: pointer;" />
                </q-breadcrumbs>
            </q-toolbar>
        </q-header>


        <q-page-container class="fondo">
            <router-view />
        </q-page-container>

        <q-footer style="height: 30px;" bordered class="bg-dark  text-white">
        </q-footer>
    </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";

export default {

    components: {
    },
    data() {
        return {
        }
    },

    watch: {

    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        letra() {
            return this.$store.state.usuario.usuario.charAt(0).toUpperCase();
        },
    },
    // created() {
    //     this.$store.dispatch("autoLogin");
    // },
    methods: {
        irInicio() {
            this.$router.push({ name: 'Home' })
        },
        irUsuarios() {
            this.$router.push({ name: 'ListaUsuarios' })
        },
        irVisitas() {
            this.$router.push({ name: 'ListaVisitas' })
        },
        irDeudores() {
            this.$router.push({ name: 'ListaDeudores' })
        },
        salir() {
            this.$q.loading.show({ message: "<b>Cerrando Sesión...</b>" });
            try {
                this.$q.loading.hide();
                this.$store.dispatch("salir");
            } catch (err) {
                // console.log(err);
                this.$q.loading.hide();
            }
        }
    }
}
</script>
<style></style>
