<template>
    <div id="map" style="height: 500px;"></div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default {
    name: 'MapComponent',
    props: {
        latitude: {
            type: Number,
            required: true
        },
        longitude: {
            type: Number,
            required: true
        }
    },
    mounted() {
        // Inicializa el mapa cuando el componente se monta
        const map = L.map('map').setView([this.latitude, this.longitude], 13);

        // Capa del mapa usando OpenStreetMap
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 30,
            attribution: '© OpenStreetMap'
        }).addTo(map);

        const icon = L.icon({
            iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
            shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
        });

        // Añade un marcador en las coordenadas proporcionadas
        L.marker([this.latitude, this.longitude], { icon }).addTo(map)
            .bindPopup('Ubicación marcada.')
            .openPopup();
    }
};
</script>

<style scoped>
#map {
    width: 100%;
    height: 500px;
}
</style>