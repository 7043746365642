<template>
  <q-layout style="background: #f1f2f4;" view="hHh lpR fFf">

    <q-header>
      <q-toolbar style="background-color: #f1f2f4;" class="no-shadow bg-dark q-pa-sm q-px-xl">
        <!-- <q-btn flat round icon="mdi-wallet" color="primary" class="q-mr-sm" /> -->
        <q-toolbar-title class="q-mt-sm">
          <img src="../assets/image.png" width="200" alt="" />
          <!-- <div class="text-h6 text-primary">SISTEMA NOTIFICADOR</div> -->
        </q-toolbar-title>
        <q-space />
        <div class="text-h6 text-primary q-mr-lg">
          <!-- {{ this.$store.state.usuario.username }} -->
        </div>
        <q-btn flat round>
          <q-avatar color="grey" text-color="dark">{{ letra }}</q-avatar>
          <q-menu>
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup @click="salir()">
                <q-item-section avatar>
                  <q-icon color="dark" name="mdi-logout" />
                </q-item-section>
                <q-item-section>Cerrar Sesión</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <!-- <q-btn color="primary" flat round icon="mdi-logout" class="q-mr-sm" @click="salir()" /> -->
      </q-toolbar>
    </q-header>

    <div class="q-px-xl q-mx-sm q-mt-xl q-pt-lg q-gutter-sm">
      <q-card class="shadow-0" style="width: 100%;">
        <q-card-section full-width class=" text-white">
          <div class="row">
            <div class="col-12 col-md-12">
              <!-- <span class="text-h3">SISTEMA</span> -->
            </div>
          </div>

          <div class="text-center ">
            <img height="300px" src="../assets/logo_control.png">
          </div>

          <div class="row	flex-center q-pr-xl q-pl-xl q-pt-xl">
            <div class="col-12 col-md-1 text-center q-pb-xl q-mx-lg">
              <span class="q-pa-lg bg-dark shadow-6 mb-3 inline-block surface-card span-hover"
                style="border-radius: 10px; cursor: pointer; width: 125px;" @click="irUsuarios()">
                <q-icon name="mdi-account-group" class="icon-hover" style="color: aliceblue; font-size: 3em;"></q-icon>
                <div class="q-mt-sm text-weight-bolder text-hover" style="font-size:18px; color: aliceblue;">Usuarios
                </div>
              </span>
            </div>

            <div class="col-12 col-md-1 text-center q-pb-xl  q-mx-lg">
              <span class="q-pa-lg bg-dark  shadow-6 mb-3 inline-block surface-card"
                style=" border-radius: 10px; cursor: pointer; width: 125px;" @click="irVisitas()">
                <q-icon name="mdi-map-marker-multiple" style="color: aliceblue;font-size: 3em;" />
                <div class="q-mt-sm text-weight-bolder" style="font-size:18px;color: aliceblue;">Visitas</div>
              </span>
            </div>

            <div class="col-12 col-md-1 text-center q-pb-xl  q-mx-lg">
              <span class="q-pa-lg bg-dark  shadow-6 mb-3 inline-block surface-card"
                style=" border-radius: 10px; cursor: pointer; width: 125px;" @click="irDeudores()">
                <q-icon name="mdi-account-multiple-remove" style="color: aliceblue;font-size: 3em;" />
                <div class="q-mt-sm text-weight-bolder" style="font-size:18px;color: aliceblue;">Deudores</div>
              </span>
            </div>
          </div>

          <div class="row q-col-gutter-sm">
            <div class="col-12 col-md-6 q-mt-md ">
              <div class="text-h5 text-black">Reportes de Visitas</div>
            </div>
            <div class="col-12 col-md-3">
              <q-select dense filled v-model="consultaReporte" fill-input multiple emit-value map-options
                :options="['Estatus', 'Usuario', 'Municipio', 'Localidad', 'Colonia', 'Código Postal', 'Tipo de Visita', 'Fecha de Visita']"
                label="Filtros">
                <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
                  <q-item v-bind="itemProps" v-on="itemEvents">
                    <q-item-section>
                      <q-item-label v-html="opt"></q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle :value="selected" @input="toggleOption(opt)" />
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-12 col-md-3 text-right">
              <q-btn color="dark" icon="mdi-table" class="text-whithe q-mr-sm" label="GENERAR REPORTE"
                @click="getReporteVisitas()" />
              <q-btn round class="q-ml-sm text-primary" color="dark" icon="mdi-file-excel" @click="exportTable()">
                <q-tooltip>
                  Generar Excel
                </q-tooltip>
              </q-btn>
              <q-btn class="q-ml-sm text-primary" round color="dark" icon="mdi-file-pdf-box" @click="exportPDF()">
                <q-tooltip>
                  Generar PDF
                </q-tooltip>
              </q-btn>
            </div>
          </div>

          <div class="row q-col-gutter-sm q-mt-md">
            <div v-if="consultaReporte.length != 0" class="col-12 col-md-2">
              <div class="row q-col-gutter-sm">
                <div v-if="banderaEstatus" class="col-12">
                  <q-select full-width dense filled v-model="estadoVisita" fill-input
                    :options="['Asignado', 'Visitado', 'Pagado', 'Cancelado']" label="Consultar por Estatus">
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No results
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div v-if="banderaUsuario" class="col-12">
                  <q-select full-width dense filled v-model="usuarioVisita" fill-input :options="itemsUsuarios"
                    option-label="nombre" label="Consultar por Usuario">
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No results
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div v-if="banderaMunicipio || banderaLocalidad || banderaColonia" class="col-12">
                  <q-select dense filled v-model="municipio" use-input hide-selected fill-input input-debounce="0"
                    :options="itemsFilterDeudoresMunicipios" @filter="filterMunicipios" label="Filtrar por Municpio"
                    @input="getListaLocalidad()">
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No results
                        </q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:prepend>
                      <q-icon name="mdi-magnify" />
                    </template>
                  </q-select>
                </div>

                <div v-if="banderaLocalidad || banderaColonia" class="col-12">
                  <q-select dense filled v-model="localidad" use-input hide-selected fill-input input-debounce="0"
                    :options="itemsFilterDeudoresLocalidades" @filter="filterLocalidades" label="Filtrar por localidad"
                    @input="getListaColonias()" :loading="loadingL">
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No results
                        </q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:prepend>
                      <q-icon name="mdi-magnify" />
                    </template>
                  </q-select>
                </div>
                <div v-if="banderaColonia" class="col-12">
                  <q-select dense filled v-model="colonia" use-input hide-selected fill-input input-debounce="0"
                    :options="itemsFilterDeudoresColonias" @filter="filterColonias" label="Filtrar por Colonia"
                    :loading="loadingC">
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No results
                        </q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:prepend>
                      <q-icon name="mdi-magnify" />
                    </template>
                  </q-select>
                </div>
                <div v-if="banderaCodigoPostal" class="col-12">
                  <q-select dense filled v-model="codigoPostal" use-input hide-selected fill-input input-debounce="0"
                    :options="itemsFilterDeudoresCodigos" @filter="filterCodigos" label="Filtrar por Código Postal"
                    :loading="loadingCP">
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No results
                        </q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:prepend>
                      <q-icon name="mdi-magnify" />
                    </template>
                  </q-select>
                </div>
                <div v-if="banderaTipoVisita" class="col-12">
                  <q-select full-width dense filled v-model="tipoVisita" fill-input
                    :options="['Carta Invitación', 'Citatorio', 'Requerimiento']" label="Consultar por tipo de Visita">
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          No results
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
                <div v-if="banderaFechaVisita" class="col-12">
                  <q-input v-model="fehaIMasked" filled label="Fecha de Visita" class="q-mr-sm" name="event" outlined
                    dense>
                    <template v-slot:before>
                      <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                      <q-date v-model="fechaI">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-input>
                </div>
              </div>
            </div>
            <div :class="classcol">
              <q-table :data="data" class="shadow-2" :columns="columns" row-key="name" :filter="filter"
                :loading="loading" :pagination="{ rowsPerPage: 10 }">
                <template v-slot:top-right>
                  <q-input borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </template>
                <template v-slot:body="props">
                  <q-tr :props="props">
                    <q-td key="folio" :props="props">
                      {{ props.row.folio }}
                    </q-td>
                    <q-td key="nombre" :props="props">
                      {{ props.row.nombre }}
                    </q-td>
                    <q-td key="placa" :props="props">
                      {{ props.row.placa }}
                    </q-td>
                    <q-td key="codigoPostal" :props="props">
                      {{ props.row.codigoPostal }}
                    </q-td>
                    <q-td key="municipio" :props="props">
                      {{ props.row.municipio }}
                    </q-td>
                    <q-td key="localidad" :props="props">
                      {{ props.row.localidad }}
                    </q-td>
                    <q-td key="colonia" :props="props">
                      {{ props.row.colonia }}
                    </q-td>
                    <q-td key="calle" :props="props">
                      {{ props.row.calle }}
                    </q-td>
                    <q-td key="total_pagar_descuentos" :props="props">
                      {{ props.row.total_pagar_descuentos }}
                    </q-td>
                    <q-td key="georef" :props="props">
                      {{ props.row.georef }}
                    </q-td>
                    <q-td key="nombreUsuario" :props="props">
                      {{ props.row.nombreUsuario }}
                    </q-td>
                    <q-td key="tipoVisita" :props="props">
                      {{ props.row.tipoVisita }}
                    </q-td>
                    <q-td key="estatus" :props="props">
                      <q-badge v-if="props.row.estatus == 'Asignado'" color="purple">
                        {{ props.row.estatus }}
                      </q-badge>
                      <q-badge v-else-if="props.row.estatus == 'visitado'" color="green">
                        {{ props.row.estatus }}
                      </q-badge>
                      <q-badge v-else-if="props.row.estatus == 'Cancelada'" color="red">
                        {{ props.row.estatus }}
                      </q-badge>
                      <q-badge v-else color="blue">
                        {{ props.row.estatus }}
                      </q-badge>
                    </q-td>
                    <q-td key="fecha_visita" :props="props">
                      {{ props.row.fecha_visita }}
                    </q-td>
                    <q-td key="notas" :props="props">
                      {{ props.row.notas }}
                    </q-td>
                  </q-tr>
                </template>
              </q-table>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <q-footer style="height: 30px" bordered class="bg-dark text-white">
      <q-toolbar> </q-toolbar>
    </q-footer>
  </q-layout>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'
import { exportFile } from 'quasar'
import moment from 'moment';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { ReporteVisitasPDF } from '../components/Main/PDF/ReporteVisitasPDF'
function wrapCsvValue(val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  return `"${formatted}"`
}

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  data() {
    return {
      columns: [
        {
          name: 'folio',
          field: 'folio',
          required: true,
          label: 'Folio',
          align: 'center',
          sortable: true,
          classes: 'bg-grey-2 ellipsis',
          style: 'max-width: 100px',
          headerClasses: 'bg-primary text-white'
        },
        { name: 'nombre', align: 'left', label: 'Deudor', field: 'nombre', sortable: true },
        { name: 'placa', align: 'center', label: 'Placa', field: 'placa', sortable: true },
        { name: 'codigoPostal', align: 'center', label: 'Código Postal', field: 'codigoPostal' },
        { name: 'municipio', align: 'center', label: 'Municipio', field: 'municipio' },
        { name: 'localidad', align: 'center', label: 'Localidad', field: 'localidad' },
        { name: 'colonia', align: 'center', label: 'Fraccionamiento o Colonia', field: 'colonia' },
        { name: 'calle', align: 'center', label: 'Calle', field: 'calle' },
        { name: 'total_pagar_descuentos', align: 'center', label: 'Adeudo', field: 'total_pagar_descuentos' },
        { name: 'georef', align: 'center', label: 'Georeferencia', field: 'georef' },
        { name: 'nombreUsuario', align: 'center', label: 'Usuario', field: 'nombreUsuario' },
        { name: 'tipoVisita', align: 'center', label: 'Tipo de Visita', field: 'tipoVisita' },
        { name: 'estatus', align: 'center', label: 'Estado de Visita', field: 'estatus' },
        { name: 'fecha_visita', align: 'center', label: 'Fecha de Visita', field: 'fecha_visita' },
        { name: 'notas', align: 'center', label: 'Notas', field: 'notas' },
      ],
      data: [],
      filter: '',
      loading: false,

      usuarios: [],
      filterUsuarios: [],
      model: { nombre: '' },
      usuarioVisita: { nombre: '' },
      estadoVisita: '',
      municipio: '',
      localidad: '',
      colonia: '',
      codigoPostal: '',
      fechaI: new Date(),
      tipoVisita: '',
      consultaReporte: [],

      itemsColonias: [],
      itemsMunicipio: [],
      itemsLocalidad: [],
      itemsCodigoPostal: [],
      itemsFilterDeudoresColonias: [],
      itemsFilterDeudoresMunicipios: [],
      itemsFilterDeudoresLocalidades: [],
      itemsFilterDeudoresCodigos: [],
      loadingL: false,
      loadingC: false,
      loadingCP: false,
    }
  },
  created() {
    this.getListaUsuarios();
    this.getListaMunicipio();
    this.getListaCodigoPostal();
  },
  computed: {
    itemsVisitasAsignadas() {
      return this.data.filter(x => x.estatus == 'asignado');
    },
    letra() {
      return this.$store.state.usuario.usuario.charAt(0).toUpperCase();
    },
    itemsUsuarios() {
      return this.usuarios.filter(x => x.estatus == 'activo' && x.tipo == 'Movil');
    },
    banderaEstatus() {
      let estatus = "Estatus"
      const existeEnLista = this.consultaReporte.includes(estatus);
      return existeEnLista
    },
    banderaUsuario() {
      let estatus = "Usuario"
      const existeEnLista = this.consultaReporte.includes(estatus);
      return existeEnLista
    },
    banderaMunicipio() {
      let estatus = "Municipio"
      const existeEnLista = this.consultaReporte.includes(estatus);
      return existeEnLista
    },
    banderaLocalidad() {
      let estatus = "Localidad"
      const existeEnLista = this.consultaReporte.includes(estatus);
      return existeEnLista
    },
    banderaColonia() {
      let estatus = "Colonia"
      const existeEnLista = this.consultaReporte.includes(estatus);
      return existeEnLista
    },
    banderaCodigoPostal() {
      let estatus = "Código Postal"
      const existeEnLista = this.consultaReporte.includes(estatus);
      return existeEnLista
    },
    banderaTipoVisita() {
      let estatus = "Tipo de Visita"
      const existeEnLista = this.consultaReporte.includes(estatus);
      return existeEnLista
    },
    banderaFechaVisita() {
      let estatus = "Fecha de Visita"
      const existeEnLista = this.consultaReporte.includes(estatus);
      return existeEnLista
    },
    fehaIMasked() {
      moment.locale('es-mx');
      return this.fechaI ? moment(this.fechaI).format('yyyy-MM-DD') : ''
    },
    classcol() {
      if (this.consultaReporte.length == 0) {
        return 'col-12 col-md-12'
      } else {
        return 'col-12 col-md-10'

      }
    }
  },
  methods: {
    async getListaColonias() {
      this.itemsColonias = []
      this.loadingC = true

      try {
        let response = await axios.get("Deudores/GetColoniasDeudores/" + this.localidad);
        this.itemsColonias = response.data;
        this.loadingC = false
      } catch (error) {
        console.log(error);
        this.loadingC = false

      }
    },
    async getListaMunicipio() {
      this.itemsMunicipio = []
      this.colonia = ""
      try {
        let response = await axios.get("Deudores/GetMunicipiosDeudores");
        this.itemsMunicipio = response.data;
        this.localidad = ""

      } catch (error) {
        console.log(error);
      }
    },
    async getListaLocalidad() {
      this.loadingL = true
      this.colonia = ''
      this.itemsLocalidad = []
      try {
        let response = await axios.get("Deudores/GetLocalidadesDeudores/" + this.municipio);
        this.itemsLocalidad = response.data;
        this.loadingL = false

      } catch (error) {
        console.log(error);
        this.loadingL = false
      }
    },
    async getListaCodigoPostal() {
      this.itemsCodigoPostal = []
      this.loadingCP = true
      try {
        let response = await axios.get("Deudores/GetCodigPostalDeudores");
        this.itemsCodigoPostal = response.data;
        this.loadingCP = false
      } catch (error) {
        this.loadingCP = false
        console.log(error);
      }
    },
    async getListaUsuarios() {
      try {
        let response = await axios.get("Usuarios/GetUsuarios");
        this.usuarios = response.data.lista;
      } catch (error) {
        console.log(error);
      }
    },
    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        this.filterUsuarios = this.itemsUsuarios.filter(v => v.nombre.toLocaleLowerCase().indexOf(needle) > -1)
      },
        ref => {
          if (val !== '' && this.filterUsuarios.length > 0) {
            ref.setOptionIndex(-1)
            ref.moveOptionSelection(1, true)
          }
        }
      )
    },
    async getReporteVisitas() {

      if (this.banderaUsuario == true) {
        if (this.usuarioVisita.nombre == '') {
          this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione el nombre del usuario.' })
          return;
        }
      } else {
        this.usuarioVisita = { nombre: '' }
      }

      if (this.banderaEstatus == true) {
        if (this.estadoVisita == '') {
          this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione el estatus de la visita.' })
          return;
        }
      } else {
        this.estadoVisita = ''
      }

      if (this.banderaMunicipio == true || this.banderaLocalidad == true || this.banderaColonia == true) {
        if (this.municipio == '') {
          this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione el municipio de la visita.' })
          return;
        }
      } else {
        this.municipio = ''
      }

      if (this.banderaLocalidad == true || this.banderaColonia == true) {
        if (this.localidad == '') {
          this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione la localidad de la visita.' })
          return;
        }
      } else {
        this.localidad = ''
      }

      if (this.banderaColonia == true) {
        if (this.colonia == '') {
          this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione la colonia de la visita.' })
          return;
        }
      } else {
        this.colonia = ''
      }

      if (this.banderaCodigoPostal == true) {
        if (this.codigoPostal == '') {
          this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione un Código Postal.' })
          return;
        }
      } else {
        this.codigoPostal = ''
      }

      if (this.banderaTipoVisita == true) {
        if (this.tipoVisita == '') {
          this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione el tipo de visita.' })
          return;
        }
      } else {
        this.tipoVisita = ''
      }

      if (this.banderaFechaVisita == true) {
        if (this.fecha_visita == '') {
          this.$q.notify({ type: 'warning', position: 'top-right', message: 'Seleccione la fecha para la consulta.' })
          return;
        }

      } else {
        this.fecha_visita = ''
      }

      let objeto = {
        estatus: this.estadoVisita,
        usuario: this.usuarioVisita.nombre,
        municipio: this.municipio,
        localidad: this.localidad,
        colonia: this.colonia,
        codigoPostal: this.codigoPostal,
        tipoVisita: this.tipoVisita,
        fechaVisita: this.fecha_visita
      }
      this.loading = true

      console.log(objeto)
      try {
        let response = await axios.post("Visita/GetReporteVisitas/", objeto);
        this.data = response.data;
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error);
      }
    },

    async salir() {
      this.$q.loading.show({ message: "<b>Cerrando Sesión...</b>" });
      try {
        this.$q.loading.hide();
        this.$store.dispatch("salir");
      } catch (err) {
        console.log(err);
        this.$q.loading.hide();
      }
    },

    irUsuarios() {
      this.$router.push({ name: 'ListaUsuarios' });
    },
    irVisitas() {
      this.$router.push({ name: 'ListaVisitas' })
    },
    irDeudores() {
      this.$router.push({ name: 'ListaDeudores' })
    },

    filterColonias(val, update, abort) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        this.itemsFilterDeudoresColonias = this.itemsColonias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
      },
        ref => {
          if (val !== '' && this.itemsFilterDeudoresColonias.length > 0) {
            ref.setOptionIndex(-1)
            ref.moveOptionSelection(1, true)
          }
        }
      )
    },

    filterMunicipios(val, update, abort) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        this.itemsFilterDeudoresMunicipios = this.itemsMunicipio.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
      },
        ref => {
          if (val !== '' && this.itemsFilterDeudoresMunicipios.length > 0) {
            ref.setOptionIndex(-1)
            ref.moveOptionSelection(1, true)
          }
        }
      )
    },
    filterLocalidades(val, update, abort) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        this.itemsFilterDeudoresLocalidades = this.itemsLocalidad.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
      },
        ref => {
          if (val !== '' && this.itemsFilterDeudoresLocalidades.length > 0) {
            ref.setOptionIndex(-1)
            ref.moveOptionSelection(1, true)
          }
        }
      )
    },
    filterCodigos(val, update, abort) {
      update(() => {
        const needle = val.toLocaleLowerCase()
        this.itemsFilterDeudoresCodigos = this.itemsCodigoPostal.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
      },
        ref => {
          if (val !== '' && this.itemsFilterDeudoresCodigos.length > 0) {
            ref.setOptionIndex(-1)
            ref.moveOptionSelection(1, true)
          }
        }
      )
    },
    async exportPDF() {
      const pdfBase64 = await ReporteVisitasPDF(this.data, moment(new Date).format('yyyy-MM-DD'));
      const fileName = 'ReporteVisitasPDF.pdf';
      const link = document.createElement("a");
      link.href = pdfBase64;
      link.download = fileName;
      link.click();
    },

    exportTable() {
      // naive encoding to csv format
      const content = [this.columns.map(col => wrapCsvValue(col.label))].concat(
        this.data.map(row => this.columns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === void 0 ? col.name : col.field],

          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'table-export.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },
  }
}
</script>
<style>
.degradado {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 77, 64, 1) 100%);
}

.span-hover:hover {
  background-color: aliceblue;
}

.span-hover:hover .icon-hover,
.span-hover:hover .text-hover {
  color: #004d40;
}
</style>
