<template>
  <q-layout>
    <q-page-container class="flex flex-center">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
//import Inicio from "./views/Home.vue"
import Inicio from "./components/Main/MainPage.vue"
import axios from 'axios'
import { colors } from 'quasar'
export default {
  name: 'LayoutDefault',

  components: {
    Inicio
  },

  data() {
    return {
      leftDrawerOpen: false
    }
  },
  computed: {
    token() {
    },
  },
  created() {
    this.$store.dispatch("autoLogin");
  },
  methods: {

  }
}
</script>
